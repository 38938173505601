import { TweenLite } from 'gsap';
import 'gsap/ScrollToPlugin';

const scroll = {
  scrollBtn: document.querySelector('.js-scroll-btn'),
  scrollTo: document.querySelector('.js-scroll-to'),

  scroll() {
    TweenLite.to(window, 0.5, {scrollTo: {y: this.scrollTo.offsetTop}});
  },

  init() {
    if (this.scrollBtn) {
      this.scrollBtn.addEventListener('click', this.scroll.bind(this));
    }
  }
}

export { scroll }
