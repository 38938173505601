/* global google, fisheries */
import $ from 'jquery';
window.$ = $;

import { utils } from './utils';
import { TweenLite } from 'gsap';
import 'gsap/ScrollToPlugin';

Element.prototype.closest = Element.prototype.closest || utils.closest;

window.initMap = () => {
  const map = new google.maps.Map(document.getElementById('map'), {
    center: {lat: 38.7223, lng: 9.1393},
    // center: {lat: 51.5074, lng: 0.1278},
    scrollwheel: false,
    zoom: 2,
    styles: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#76C8D4"
          }
        ]
      },
      {
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#8ec3b9"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#1a3646"
          }
        ]
      },
      {
        "featureType": "administrative",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#76C8D4"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#76C8D4"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "stylers": [
          {
            "color": "#ffffff"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#0e1626"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
    ]
  });

  const infoWindow = new google.maps.InfoWindow();
  const mapContainer = document.querySelector('.js-map-container');

  mapContainer.addEventListener('click', (event) => {
    if (event.target.closest('.infowindow__content')) {
      highlightFishery(event.target.closest('.infowindow__content').getAttribute("data-fishery-id"))
    }
  })

  function updateContentTemplate(fishery) {
    const contentTemplate = `<div class="infowindow__content" data-fishery-id=${fishery.id}>`+
                              `<h5 class="infowindow__fishery-name">${fishery.name}</h5>`+
                              `<p class="infowindow__more" >`+
                                `Find out more `+
                                `<img class="infowindow__more-chevron" src="../images/svg/circle-chevron-right.svg">`+
                              `</p>`+
                            `</div>`;
    return contentTemplate;
  }

  function highlightFishery(fisheryId) {
    const rows = document.querySelectorAll('.js-fishery-row');
    let matchingRow;

    utils.forEach(rows, (index, row) => {
      if (row.getAttribute("data-fishery-id") == fisheryId) {
        matchingRow = row;
      }
    })

    const highlightedRow = document.querySelector('.table-row-is-highlighted');

    if (highlightedRow) {
      highlightedRow.classList.remove("table-row-is-highlighted");
    }

    matchingRow.classList.add("table-row-is-highlighted");
    
    const tableHead = document.getElementById('fisheries-table-head');
    TweenLite.to(window, 0.5, {scrollTo: {y: matchingRow, offsetY: tableHead.offsetHeight}});
  }

  utils.forEach(fisheries, (index, fishery) => {
    utils.forEach(fishery.locations, (index, location) => {
      const marker = new google.maps.Marker({
        position: location,
        map: map,
        icon: "../images/map-marker.png"
      });

      google.maps.event.addListener(marker, 'click', () => {
        const newContent = updateContentTemplate(fishery);
        infoWindow.setContent(newContent);
        infoWindow.open(map, marker);
      });
    })
  });

  google.maps.event.addDomListener(window, 'resize', () => {
    const center = map.getCenter();

    google.maps.event.trigger(map, 'resize');
    map.setCenter(center);
  });
};

const map = {
  el: document.getElementById(`map`),

  loadMap() {
    document.addEventListener(`DOMContentLoaded`, () => {
      const script = document.createElement(`script`);
      script.type = `text/javascript`;
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBQ-rKqBREPBt921TAdh3E_FyFcEdNZ7C4&callback=initMap`;
      document.getElementsByTagName(`head`)[0].appendChild(script);
    });
  },

  init() {
    if (this.el) {
      this.loadMap();
    }
  }
};

export { map };
