// Ideally we would rewrite the whole search functionality in the future.
// At the moment it's a mixture of ajaxing and css show/hide :(
import { TweenLite } from 'gsap';

// This css class is specific for filtering by year
// There is a different css class for hiding according to the search term.
// See the search.js file for more details
const companies = Array.from(document.querySelectorAll('.js-company'));
const companyHideClass = 'company-is-hidden-year';
const activeYearBtnClass = 'year-is-active';
const activeMenuClass = 'year-menu-active';
const toggleBtn = document.getElementById('year-menu-toggle');
const menu = document.getElementById('year-menu-dropdown');
const options = Array.from(document.querySelectorAll('.js-year-dropdown-option'));
const btnText = document.getElementById('year-menu-toggle-text');

function toggleMenu() {
  if (menu.parentElement.classList.contains(activeMenuClass)) {
    hideMenu();
  } else {
    showMenu();
  }
}

function showMenu() {
  menu.parentElement.classList.add(activeMenuClass);
  TweenLite.set(menu, {height: 'auto'});
  TweenLite.from(menu, 0.1, {height: 0});
}

function hideMenu() {
  menu.parentElement.classList.remove(activeMenuClass);
  TweenLite.to(menu, 0.05, {height: 0});
}

function filter(year) {
  const hide = companies.filter(item => {
    const itemYears = item.dataset.years.split(',');
    return !itemYears.includes(year);
  });
  hide.forEach(item => item.classList.add(companyHideClass));
  btnText.innerHTML = year;
}

function update(e) {
  hideMenu();
  // Reset companies (in terms of year only, won't remove search.js css hide class)
  companies.forEach(item => item.classList.remove(companyHideClass));
  btnText.innerHTML = 'Year';
  // Check if current active year option selected
  const currentActiveBtn = document.querySelector(`.${activeYearBtnClass}`);

  // If year option was clicked
  if (e) {
    const clicked = e.currentTarget;
    // If clicked option is the current active option..
    if (clicked.classList.contains(activeYearBtnClass)) {
      clicked.classList.remove(activeYearBtnClass);
    } else {
      // If it's different, unselect the current active option if it exists
      if (currentActiveBtn) {
        currentActiveBtn.classList.remove(activeYearBtnClass);
      }
      clicked.classList.add(activeYearBtnClass);
      const year = clicked.value;
      filter(year);
    }
  } else {
    // If search.js calls update, check for current active year and filter by that as well
    if (currentActiveBtn) {
      const year = currentActiveBtn.value;
      filter(year);
    }
  }
}

const yearMenu = {
  init() {
    if (!toggleBtn) {
      return;
    }
    toggleBtn.addEventListener('click', toggleMenu);
    options.forEach(option => option.addEventListener('click', update));
  },
  updateCompanies: update
}

export default yearMenu;
