import Glide from '@glidejs/glide';

const el = document.getElementById('featured-carousel');

export default function() {
  if (!el) {
    return;
  }

  const carousel = new Glide(el, {
    gap: 30,
    perView: 3,
    breakpoints: {
      980: {
        perView: 2,
      },
      640: {
        perView: 1,
      },
    }
  }).mount();
}
