import { utils } from './utils';
import { TweenLite } from 'gsap';
import 'gsap/ScrollToPlugin';

const company = {
  yearsData: document.querySelectorAll('.js-year-container'),
  yearsDataButtons: document.querySelectorAll('.js-year-select'),
  fisheryDetails: document.querySelectorAll('.js-fishery-details'),
  fisheryDetailsButtons: document.querySelectorAll('.js-toggle-fishery-details'),
  toTopButton: document.querySelector('.js-to-top'),
  mapContainer: document.querySelector('.js-map-container'),

  toTop() {
    TweenLite.to(window, 0.5, {scrollTo: {y: this.mapContainer.offsetTop}});
  },

  toggleYearData(clickEvent) {
    const buttonClicked = clickEvent.currentTarget;
    const buttonShowing = document.querySelector('.year-button-is-selected');
    const yearShowing = document.querySelector('.year-is-showing');
    if (yearShowing) {
      yearShowing.classList.remove('year-is-showing');
      buttonShowing.classList.remove('year-button-is-selected');
    }
    const indexRef = buttonClicked.getAttribute("data-year-button-index");
    const matchingDiv = company.yearsData[indexRef];
    matchingDiv.classList.add('year-is-showing');
    buttonClicked.classList.add('year-button-is-selected');
  },

  toggleDetails(clickEvent) {
    const arrow = clickEvent.currentTarget.querySelector('.js-arrow');
    const indexRef = clickEvent.currentTarget.getAttribute('data-details-button-index');
    const matchingDiv = company.fisheryDetails[indexRef];

    if (!matchingDiv.classList.contains("js-is-closed")) {
      const closeTl = new TimelineLite();
      closeTl
        .to(matchingDiv, 0.1, {height:0})
        .to(arrow, 0.1, {rotation: 0}, "-=0.1");
   	  matchingDiv.classList.add("js-is-closed");
    } else {
      const openTl = new TimelineLite();
      TweenLite.set(matchingDiv, {height: "auto"});
      openTl
        .from(matchingDiv, 0.1, {height:0})
        .to(arrow, 0.1, {rotation: 180}, "-=0.1");
			matchingDiv.classList.remove("js-is-closed");
    }
  },

  init() {
    if (this.toTopButton) {
      this.toTopButton.addEventListener('click', this.toTop.bind(this))
    }

    utils.forEach(company.fisheryDetailsButtons, (index, button) => {
      button.addEventListener('click', company.toggleDetails)
    });

    utils.forEach(company.fisheryDetails, (index, fisheryDetails) => {
      fisheryDetails.classList.add("js-is-closed");
    });

    if (company.yearsData.length) {
      const mostRecentYear = company.yearsData[company.yearsData.length-1];

      mostRecentYear.classList.add('year-is-showing');
      const mostRecentYearButton = company.yearsDataButtons[company.yearsDataButtons.length-1];
      mostRecentYearButton.classList.add('year-button-is-selected');

      utils.forEach(company.yearsDataButtons, (index, button) => {
        button.addEventListener('click', company.toggleYearData)
      });
    }
  }
};

export { company };
