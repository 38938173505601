// Ideally we would rewrite the whole search functionality in the future.
// At the moment it's a mixture of ajaxing and css show/hide :(
const _ = require('lodash');
import yearMenu from './year-menu';
import * as $ from 'jquery';

const hideClass = 'company-is-hidden';
const companies = Array.from(document.querySelectorAll('.js-company'));
const $companiesList = $('.js-companies-list');
const $searchInput = $('.js-search-filter');
const $resultsMessage = $('.js-results-message');

const updateList = (data) => {
  const hide = companies.filter(item => {
    // Exclude anything that matches.
    // Probably a nicer way of doing this..
    const match = data.find(dataItem => dataItem.id.toString() === item.dataset.id);
    if (match) {
      return false;
    } else {
      return true;
    }
  });
  // Reset current
  companies.forEach(item => item.classList.remove(hideClass));
  // Then reapply css hide class for non-matching entries
  hide.forEach(item => item.classList.add(hideClass));
  // Finally, check if there's an active year and update again if necessary
  yearMenu.updateCompanies();
}

const updateResultsMessage = (companies, queryString) => {
  $resultsMessage.html(`Filtering companies by: <span class="search__string">${ queryString }</span>`);
  $resultsMessage.addClass('is-active');
}

const ajax = (queryString) => {
  $.ajax({
    url: '/companies.json',
    data: { search: queryString },
    success: function(res) {
      updateList(res.data);
      updateResultsMessage(res.data, queryString);
    }
  })
}

const searchRequest = (e) => {
  const queryString = e.target.value;
  ajax(queryString);
}

const search = {
  init() {
    $searchInput.on('input', _.debounce(searchRequest, 250));
  }
}

export { search }
