const nav = {
  menuToggle: document.querySelector(`.js-nav-toggle`),
  menu: document.querySelector(`.js-nav`),
  navLinks: Array.from(document.querySelectorAll('.js-nav-link')),

  toggleMenu() {
    nav.menuToggle.classList.toggle(`is-active`);
    nav.menu.classList.toggle(`is-active`);
  },

  init() {
    nav.menuToggle.addEventListener(`click`, nav.toggleMenu);
  }
};

export { nav };
