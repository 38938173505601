import "@babel/polyfill";

import { nav } from './modules/nav';
nav.init();

import { search } from './modules/search';
search.init();

import { map } from './modules/map';
map.init();

import { company } from './modules/company';
company.init();

import { scroll } from './modules/scroll';
scroll.init();

import carousel from './modules/carousel';
carousel();

import yearMenu from './modules/year-menu';
yearMenu.init();
